import { Component } from "react";
// import { Link } from "react-router-dom";

const subtitle = "who we are";
const title = "How “The Final 9: Battle of The Bands” works:";

class AboutSection extends Component {
  render() {
    return (
      <section className="about-section">
        <div className="container">
          <div className="section-wrapper padding-top">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="about-wrapper text-center">
                  <div className="section-header" style={{ maxWidth: "100%" }}>
                    <p className=" text-starttext-center mt-5">{subtitle}</p>
                    <h2 className="text-start">{title}</h2>
                  </div>
                  <div className=" text-start about-content position-relative">
                    <p className=" text-start mt-5">
                      Music artists from around the globe will upload their
                      ORIGINAL song(s) onto the online platform. Artists will
                      need to pay a fee to register each submission. Judges will
                      simultaneously judge these songs behind the scenes before
                      they are made public.
                    </p>
                    <p>
                      Judging lasts one month and this is also the time frame
                      for artists to upload their music, one month. “Judging”
                      and “uploading” happen simultaneously. After the judges
                      complete their "judging", they will advance the “TOP 9” in
                      each category and that will begin "The First 9 ''
                      public-voting competition. Public voting will start after
                      the judges advance their 9 finalists in each category:
                      Solo Acoustic, Pop Rock, and Hard Rock.
                    </p>
                    <p>
                      Solo Acoustic can be best described as music that makes
                      heavy use of the acoustic guitar plus singing in the likes
                      of Ed Sheeran, Crosby Still and Nash, Neil Young, Jack
                      Johnson, Elliot Smith, etc
                    </p>
                    <p>
                      Pop Rock would be music that has similar weight as U2,
                      Coldplay, Fleetwood Mac, Black Keys, Radiohead, etc.
                    </p>
                    <p>
                      Hard Rock would be music that has similar weight as Led
                      Zeppelin, Black Sabbath, Aerosmith, Van Halen, Linkin
                      Park, etc.
                    </p>
                    <p>
                      You may have music that fits all three. (Sorry, we won’t
                      be considering metal music, thrash music, etc. So no
                      Anthrax, or Megadeath, etc)
                    </p>
                    <div className=" text-start row">
                      <p>
                        So, now, beginning month TWO, the “The First Nine”
                        competition begins and will be a competition within each
                        category. So “Acoustic Solo” will only compete against
                        Acoustic Solo, “Pop Rock” will only compete against Pop
                        Rock, and “Hard Rock” will only compete against Hard
                        Rock in The First Nine competition.
                      </p>
                      <p>
                        These First Nine competitions will be voted on by the
                         <b>&nbsp;public.</b> Voters will need to register for a small
                        fee and once they do register they will receive 9 votes
                        to cast for any artist they wish in this phase of the
                        competition. They can register as many times as they
                        like for that same fee and receive nine votes again to
                        cast how they wish. After two weeks of this phase of
                        competition, and based on the public's voting, the top 3
                        in <b>EACH</b> genre will then advance to “The FInal 9”
                        competition.
                      </p>
                      <p>
                        Every artist in “The First Nine competition” will
                        receive cash awards for their music based on their final
                        ranking, one through nine. So 27 artists total will
                        receive cash awards. Cash awards will happen right after
                        this phase and will be within the genre, not cumulative
                        to all genres. So only awards in the “Acoustic Solo”
                        space will be paid to the First 9 finalists in the
                        “Acoustic Solo” category. Same for “Pop Rock” and “Hard
                        Rock”.
                      </p>
                      <p>
                        The Final 9 competition will now begin. It's the same
                        format as The First Nine and also lasts for two weeks..
                        The <b>public</b> will now vote for the best song except
                        this time, it's category vs category, song vs. song. The
                        best overall song will be revealed after two weeks of
                        this competition. At the end of The Final Nine
                        competition a TOP WINNER will be revealed and awards
                        will again happen for all artists-songs one through
                        nine. Public voting begins brand new for “The Final 9”
                        competition with the same rules as the First 9
                        competition.
                      </p>
                      <p>
                        A new “Final 9: Battle of The Bands” competition starts
                        SIX (6) times per year. This would start the clock for
                        each competition, so each upload-judge phase starts
                        every January, March, May, July, September,and November,
                        and “Public Voting” begins every February, April, June,
                        August, October, and December.
                      </p>
                      <h4>MAXIMUM awards for each phase:</h4>
                      <br />
                      <br />
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p style={{ paddingRight: "60px" }}>
                            The First 9:
                            <br />
                            (EACH genre){" "}
                          </p>

                          <p>
                            1st: up to $5000
                            <br />
                            2nd: up to $3500
                            <br />
                            3rd: up to $2000
                            <br />
                            4th-9th: up to $500
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex ">
                          <p style={{ paddingRight: "60px" }}>The Final 9:</p>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <p>
                            1st: $10,000
                            <br />
                            2nd: $7500
                            <br />
                            3rd: $5000
                            <br />
                            4-9th: $1000
                          </p>
                        </div>
                        <br />
                      </div>
                    </div>
                    <p className="fw-bold position-absolute w-100">
                      Good luck to every artist that decides to compete in the
                      “The Final 9: Battle of The Bands”. We hope to advance
                      many careers!
                    </p>
                    <b />
                    <b />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <p className="fw-bold ps-4">The Final 9 Team</p>
          <br />
          <br />
          {/* <div className=" text-startd-flex justify-content-around">
            <div>
              <Link className=" text-startdefault-button reverse-effect" to={"/signup"}>
                <span>
                  {"Register To Upload Song"}{" "}
                  <i className=" text-starticofont-play-alt-1"></i>
                </span>{" "}
              </Link>
            </div>
            <div>
              <a
                className=" text-startdefault-button reverse-effect-2"
                href={"https://artist.thefinal9.com/"}
              >
                <span>
                  {"Register To Vote"} <i className=" text-starticofont-box"></i>
                </span>{" "}
              </a>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default AboutSection;
