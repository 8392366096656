import React, { Fragment } from "react";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import PrivacyAbout from "../component/Privacy/PrivacyAbout";
import CtaPrivacy from "../component/Privacy/CtaPrivacy";
import Footer from "../component/layout/footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Fragment>
        <Header />
        <PageHeader title={"Privacy Policy"} curPage={"Privacy Policy"} />
        <PrivacyAbout imgUrl={"/assets/images/about/icon-4.png"} />
        <CtaPrivacy />
        <Footer />
      </Fragment>
    </>
  );
};

export default PrivacyPolicy;
