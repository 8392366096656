import { BrowserRouter, Route, Routes } from "react-router-dom";
import "swiper/css";
import ScrollToTop from "./component/layout/scrolltop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/contact";
import ForgotPassword from "./pages/forgotpass";
import GameListTwoSection from "./pages/gamelisttwo";
import HomePage from "./pages/home";
import LogIn from "./pages/login";
import Music from "./pages/music";
import PaytoVote from "./pages/paytovote";
import Profile from "./pages/profile";
import SignUp from "./pages/signup";
import Thefinalwork from "./pages/thefinalwork";
import ErrorPage from "./pages/errorpage";
import PaytoUserguide from "./pages/PaytoUserguide";
import Contestdates from "./pages/Contestdates";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contest_dates" element={<Contestdates />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/paytovote" element={<PaytoVote />} />
        <Route path="/paytouserguide" element={<PaytoUserguide />} />
        <Route path="/thefinalwork" element={<Thefinalwork />} />
        <Route path="/Music" element={<Music />} />
        <Route path="song" element={<GameListTwoSection />} />
        <Route path="song/:slug" element={<GameListTwoSection />} />

        <Route path="Profile" element={<Profile />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />

        <Route path="contact" element={<ContactUs />} />
        <Route path="login" element={<LogIn />} />
        <Route path="signup" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
