import { Component } from "react";

const title =
  "Privacy Policy for The Final 9: Battle of The Bands - Online Music";

class PrivacyAbout extends Component {
  render() {
    const { imgUrl } = this.props;
    return (
      <section className="about-section">
        <div className="container">
          <div className="section-wrapper padding-top">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-image">
                  <img src={imgUrl} alt="about" />
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="about-wrapper">
                  <div className="section-header">
                    <h2>{title}</h2>
                    <p>Contest Effective Date: Dec 1, 2023</p>
                  </div>
                  <div className="about-content">
                    <p>
                      The Final 9: Battle of The Bands is dedicated to
                      safeguarding the privacy of individuals who visit our
                      website and engage in our online music contest, including
                      public voting. This Privacy Policy outlines the types of
                      personal information we collect, how we use it, and the
                      steps we take to ensure its security. By using our
                      website, participating in the online music contest, and
                      paying for votes, you agree to the terms outlined in this
                      Privacy Policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivacyAbout;
