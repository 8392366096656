/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import Header from "../component/layout/header";
import HrShape from "../component/layout/hrshape";
import AboutSection from "../component/section/about";
import Footer from "../component/layout/footer";

const Thefinalwork = () => {
  return (
    <Fragment>
      <Header />
      <AboutSection imgUrl={"assets/images/about/01.png"} />
      <HrShape />
      <Footer />
    </Fragment>
  );
};

export default Thefinalwork;
