import { Component } from "react";

const title = (
  <h4 className="mb-3">
    <span className="theme-color text-uppercase">
      1. Information We Collect:
    </span>{" "}
    <h2>1 Personal Information:</h2>
  </h4>
);

class CtaPrivacy extends Component {
  render() {
    return (
      <section className="cta-section padding-bottom">
        <div className="container">
          <div className="cta-wrapper item-layer">
            <div
              className="cta-item px-4 px-sm-5 pt-4 pt-sm-5 pt-lg-0"
              style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="cta-content">
                    <p className="theme-color text-uppercase ls-2"></p>
                    {title}
                    <p className="mb-4">
                      To participate in the online music contest, we may collect
                      personal information such as:
                    </p>
                    <p>
                      ● Full name <br />
                      ● Email address <br />
                      ● Date of birth <br />● Contact information (address,
                      phone number)
                      <br />
                      i) If you register or subscribe with us;
                      <br />
                      ii) If you fill out a form on our website,
                      <br />
                      iii) If you complete our online surveys;
                      <br />
                      iv) If you enter into transactions with us;
                      <br />
                      v) If you apply for a position with us;
                      <br />
                      vi) If you provide us with feedback about the website or
                      our Services;
                      <br />
                      vii) If you correspond with us by phone, email or
                      otherwise; or
                    </p>
                    <h4>1.2 Contest Submissions:</h4>
                    <p>
                      ● We collect information related to your music contest
                      submissions, including audio files, artist biographies,
                      and any other content submitted for the contest.
                    </p>
                    <h4>1.3 Payment Information:</h4>
                    <p>
                      ● For public voting, we collect payment information from
                      voters, including credit card details, billing address,
                      and other relevant payment details. Please note that we do
                      not store payment information on our servers; it is
                      securely processed by our payment gateway
                    </p>
                    <h2>2. How We Use Your Information:</h2>
                    <p>
                      ● We use the personal data you provide on our Site for the
                      following legitimate purposes:
                    </p>
                    <h4>2.1 Contest Participation:</h4>
                    <p>
                      ● We use the personal information provided during
                      registration to set up a registration account for you,
                      including to verify your identity in connection to setting
                      up your account, as well as to facilitate your
                      participation in the online music contest, including
                      verifying eligibility and contacting you regarding your
                      submission.
                    </p>
                    <h4>2.2 Public Voting:</h4>
                    <p>
                      ● Information collected from voters is used to process
                      votes and ensure the integrity of the public voting
                      process
                    </p>
                    <h4>2.3 Communication:</h4>
                    <p>
                      ● We may use your email address and contact information to
                      communicate with you about the contest, provide updates,
                      and deliver important information related to your
                      participation and voting;
                    </p>{" "}
                    <p>
                      ● To notify you about any changes to our schedules or
                      activities;
                    </p>{" "}
                    <h4>2.4 Payment Processing:</h4>
                    <p>
                      ● Voter payment information is used solely for the purpose
                      of processing votes. We do not store this information on
                      our servers but rely on secure third-party payment
                      processors.
                    </p>
                    <h4>2.5 Improving Our Services:</h4>
                    <p>
                      ● We may use aggregated and anonymized data for analytics
                      purposes to enhance our website's functionality and
                      improve user experience.
                    </p>
                    <p>
                      ● We may also use third-party analytics services on our
                      sites, such as Google Analytics. The information we obtain
                      through our sites may be disclosed to or collected
                      directly by these services. To learn more about Google
                      Analytics, please visit&nbsp;
                      <a
                        className="theme-color"
                        href="https://www.google.com/policies/privacy/partners/."
                        rel="noreferrer"
                        target="_blank"
                      >
                        https://www.google.com/policies/privacy/partners/.
                      </a>{" "}
                      To opt-out of Google Analytics, please visit&nbsp;
                      <a
                        className="theme-color"
                        href="https://tools.google.com/dlpage/gaoptout."
                        rel="noreferrer"
                        target="_blank"
                      >
                        https://tools.google.com/dlpage/gaoptout.
                      </a>
                    </p>
                    <h4>
                      2.6 Information That We Collect Automatically And How We
                      Use It
                    </h4>
                    <p>
                      ● When you visit our website, we may obtain certain
                      information by automated means, such as cookies, web
                      beacons, web server logs and other technologies. A
                      “cookie” is a text file that websites send to a visitor’s
                      computer or other internet-connected device to uniquely
                      identify the visitor’s browser or to store information or
                      settings in the browser. A “web beacon,” also known as an
                      internet tag, pixel tag or clear GIF, links web pages to
                      web servers and cookies and may be used to transmit
                      information collected through cookies back to a web
                      server. The information we collect in this manner may
                      include your device IP address, unique device identifier,
                      web browser characteristics, device characteristics,
                      operating system, language preferences, referring URLs,
                      clickstream data, and dates and times of website visits.
                    </p>
                    <p>
                      ● We may use these automated technologies on our website
                      to collect information about your gadget, browsing
                      actions, and usage patterns. These technologies help us
                      to:
                    </p>
                    <p>
                      i) remember your information so you do not have to
                      re-enter it;
                      <br />
                      ii) track and understand how you use and interact with our
                      website;
                      <br />
                      iii) tailor the website around your preferences;
                      <br />
                      iv) measure the usability of our website and the
                      effectiveness of our communications; and
                      <br />
                      v) otherwise manage and enhance our activities, and help
                      ensure they meet user’s expectations.
                    </p>
                    {/* <Link to="/signup" className="default-button"><span>{btnText} <i className="icofont-circled-right"></i></span></Link> */}
                  </div>
                    <p>
                      ● Your browser may tell you how to be notified about
                      certain types of automated collection technologies and how
                      to restrict or disable them. 
                    </p>
                </div>
                <div className="col-lg-6">
                  <div className="cta-content">  
                    <p>
                    Please note, however, that
                      without these technologies, you may not be able to use all
                      of the features of our website. For mobile devices, you
                      can manage how your device and browser share certain
                      device data by adjusting the privacy and security settings
                      on your mobile device
                    </p>
                    <h2 className="mb-4">3. Information Sharing:</h2>
                    <h4>3.1 We do not sell, trade, or otherwise transfer your personal information to outside parties:</h4>
                    <p>
                      
                       However, we may
                      share aggregated and anonymized data with trusted third
                      parties who assist us in operating our website and
                      conducting the online music contest.
                    </p>
                    <h4>3.2 we may disclose personal data about you:</h4>
                    <p>
                      ● if we are required or permitted to do so by law or legal
                      process, for example due to a court order or a request
                      from a law enforcement agency,
                    </p>
                    <p>
                      ● when we believe disclosure is necessary or appropriate
                      to prevent physical harm or financial loss,
                    </p>
                    <p>
                      ● in connection with an investigation of suspected or
                      actual fraudulent or other illegal activity, and
                    </p>
                    <p>
                      ● in the event we sell or transfer all or a portion of our
                      business or assets, including in the event of a merger,
                      reorganization, dissolution, or liquidation.
                    </p>
                    <h2>4. Security Measures:</h2>
                    <h4>4.1 We implement robust security measures:</h4>
                    <p>
                       including
                      encryption, access controls, and regular security
                      assessments, to safeguard your personal information.
                    </p>
                    <h4> 4.2 We maintain administrative:</h4>
                    <p>
                      technical and physical
                      safeguards designed to protect the personal data we obtain
                      against accidental, unlawful or unauthorized access,
                      destruction, loss, alteration, disclosure or use.
                    </p>
                    <h4>4.3 Data Retention</h4>
                    <p>
                      ● To the extent required by applicable law, we keep the
                      personal data you provide for the duration of our
                      relationship, plus a reasonable period to comply with the
                      applicable statute of limitations or if otherwise required
                      under applicable law, unless a shorter retention period is
                      required by applicable law
                    </p>
                    <h2>5. Your Rights And Choices</h2>
                    <h4>5.1 Depending on where you are located and subject to
                    applicable law</h4>
                    <p>
                       you may have the following rights in
                      connection with the personal data we process about you:
                      <br />
                      i) The right to access and obtain a copy of your personal
                      data;
                      <br />
                      ii) The right to correct inaccuracies in information we
                      have about you;
                      <br />
                      iii) The right to object to the processing of your
                      personal data on grounds relating to your particular
                      situation;
                      <br />
                      iv) The right to have your personal data “erased” or to
                      restrict the processing of such information; or
                      <br />
                      v) Where you have given us your consent, the right to
                      withdraw consent for future processing of your
                      information.
                    </p>{" "}
                    <h4>5.2 The right to request that we provide a copy of your
                    personal data to you in a structured</h4>
                    <p>
                       commonly used and
                      machine-readable format in certain circumstances. You can
                      tell us at any time not to send you marketing
                      communications by e-mail by clicking on the unsubscribe
                      link within the marketing emails you receive from us, or
                      by sending an “opt out” request to the address indicated
                      on such email. You may also request not to be contacted by
                      us in connection with any new services, updates, news, or
                      events by contacting us as described below
                    </p>
                    <h2>6. Children’s Privacy</h2>
                    <p>
                      Our website is designed for a general audience and is not
                      directed to children. In connection with the website, we
                      do not knowingly solicit or collect personal data from
                      children under the age of 16. If we learn that we have
                      collected personal data from a child under age 13 without
                      parental consent, we will either seek parental consent or
                      promptly delete that information. If you believe that a
                      child under age 13 may have provided us with personal data
                      without parental consent, please contact us as at
                      thefinal9corp@gmail.com.
                    </p>{" "}
                    <h2>7. Changes to the Privacy Policy:</h2>
                    <p>
                      ● We reserve the right to update and modify this Privacy
                      Policy at any time. We will notify you of any changes by
                      posting the updated policy on our website.
                    </p>
                    <h2>8. Contact Information:</h2>
                    <p>
                      ● If you have any questions or concerns regarding this
                      Privacy Policy, please contact us at
                      thefinal9corp@gmail.com.
                    </p>
                    <p className="them-color">
                      By using our website, participating in the online music
                      contest, and paying for votes, you acknowledge that you
                      have read and agree to this Privacy Policy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CtaPrivacy;
