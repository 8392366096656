/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import Header from "../component/layout/header";
import Footer from "../component/layout/footer";
import Userguide from "../component/section/userguide";

const PaytoUserguide = () => {
  return (
    <Fragment>
    <Header />
    <Userguide  />
    <Footer />
  </Fragment>
  );
};

export default PaytoUserguide;
