import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content d-flex justify-content-between">
                <p>
                  &copy;2022 <Link to="/">HnhTechSolutions</Link>- All Right
                  Resevered
                </p>
                <p>
                  <Link to="/contact"> Contact </Link>&nbsp;|&nbsp;
                  <Link to="/privacyPolicy">Privacy Policy </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
