/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../component/layout/header";
import BannerSection from "../component/section/banner";
import { axiosInstance } from "../config/AxiosInstance";
import Footer from "../component/layout/footer";

const HomePage = () => {
  const [event, setEvent] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const Events = () => {
    setLoader(true);
    axiosInstance
      .get("/events")
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setLoader(false);
          // dispatch(setEvents(response.data.data.data));
          setEvent(response.data.data.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  useEffect(() => {
    Events();
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("success")) {
      toast.success("Payment SuccessFully Done!");
      navigate("/");
    } else if (urlParams.has("failed")) {
      toast.error("Payment Failed!");
      navigate("/");
    }
  }, []);
  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <BannerSection event={event} />
      <Footer />
    </Fragment>
  );
};

export default HomePage;
