import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../config/AxiosInstance";
import SVGComponent from "../assets/images/SVGComponent";

const ForgotPassword = () => {
  const [values, setValues] = useState({
    token: "",
    password: "",
    password_confirmation: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const [resetPassword, setResetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const Forgot = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    formdata.append("email", email);
    axiosInstance
      .post("voter/forget", formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setResetPassword(true);
          setLoader(false);
        } else {
          setLoader(false);
          const errors=response?.data?.errors
            if(errors){
          errors?.map((error) => toast.error(error))
            }else{toast.error(response.data.message)}
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
        setLoader(false);
      });
  };
  const Reset = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        formdata.append(key, values[key]);
      }
    }
    axiosInstance
      .post("voter/reset_password", formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          toast.success(response.data.response);
          navigate("/login");
          setLoader(false);
        } else {
          setLoader(false);
const errors=response?.data?.errors
            if(errors){
          errors?.map((error) => toast.error(error))
            }else{toast.error(response.data.message)}        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
        setLoader(false);
      });
  };
  return (
    <>
      {/* <Header /> */}
      {/* <PageHeader title={"Forget Password"} curPage={"Forget Password"} /> */}
      <div className="login-section vh-100 d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="account-wrapper p-5">
            <div className="mb-3">
              {resetPassword ? (
                <SVGComponent />
              ) : (
                <h3 className="title">Forgot your password?</h3>
              )}
            </div>
            {resetPassword ? (
              <p>
                We have sent a confirmation email to{" "}
                <strong>
                  <i>{email}</i>
                </strong>
              </p>
            ) : (
              <p>
                Please enter the email address associated with your account and
                We will email you a link to reset your password.
              </p>
            )}
            {resetPassword ? (
              <form className="account-form" onSubmit={Reset}>
                <div className="form-group">
                  <input
                    type="tel"
                    name="token"
                    id="item01"
                    value={values.token}
                    onChange={handleChange}
                    placeholder="OTP *"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    id="item01"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Password *"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password_confirmation"
                    id="item01"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    placeholder="Password Confirmation *"
                  />
                </div>

                <div className="form-group">
                  <button
                    className="d-block default-button"
                    disabled={loader}
                    type="submit"
                  >
                    {loader ? (
                      <span>
                        Loading{" "}
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="fa-spin-pulse"
                        />{" "}
                      </span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <form className="account-form" onSubmit={Forgot}>
                <div className="form-group">
                  <input
                    type="email"
                    name="name"
                    id="item01"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email *"
                  />
                </div>

                <div className="form-group">
                  <button
                    className="d-block default-button"
                    disabled={loader}
                    type="submit"
                  >
                    {loader ? (
                      <span>
                        Loading{" "}
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="fa-spin-pulse"
                        />{" "}
                      </span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            )}
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Have an Account? <Link to="/login"> Login </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
