// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventsReducer from "../slice/eventslice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, eventsReducer);

const store = configureStore({
  reducer: {
    // counter: persistedReducer,
    events: persistedReducer, // Add events slice to the store
  },
});

export const persistor = persistStore(store);

export default store;
