import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { axiosInstance } from "../config/AxiosInstance";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Footer from "../component/layout/footer";
const title = "Login";

const LogIn = () => {
  const IsTrue = sessionStorage.getItem("SignUp");
  console.log(typeof IsTrue, "<======IsTrue");
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const Login = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    formdata.append("email", userName);
    formdata.append("password", userPass);
    formdata.append("type", "voter");
    axiosInstance
      .post("login", formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          toast.success("Login Successfully");
          sessionStorage.setItem(
            "userDetail",
            JSON.stringify(response.data.data)
          );
          sessionStorage.setItem("Token", response.data.data.access_token);

          navigate("/");

          setLoader(false);
        } else {
          setLoader(false);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
        setLoader(false);
      });
  };
  return (
    <>
      <Header />
      <PageHeader title={"LOGIN FOR USER"} curPage={"Login"} />
      <div className="login-section padding-top padding-bottom">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="item01"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="User Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  id="item02"
                  value={userPass}
                  onChange={(e) => setUserPass(e.target.value)}
                  placeholder="Password *"
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <Link to="/forgot_password">Forget Password?</Link>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="d-block default-button"
                  disabled={loader}
                  onClick={Login}
                >
                  {loader ? (
                    <span>
                      Loading{" "}
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin-pulse"
                      />{" "}
                    </span>
                  ) : (
                    <span>Login</span>
                  )}
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/signup"> Sign Up</Link>
              </span>
            </div>
            <div className="account-bottom" style={{ paddingTop: "20px" }}>
              <span className="d-block cate pt-10">
                You can review <Link to="/privacyPolicy"> Privacy policy</Link>
              </span>
            </div>
            {/* <span className="or">
                <span>or</span>
              </span>
              <h5 className="subtitle">Login With Social Media</h5>
              <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center mt-4">
                <SocialMedia />
              </ul> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LogIn;
