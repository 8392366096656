// eventsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const eventsSlice = createSlice({
  name: "events",
  initialState: {
    data: [], // Initial state for events
    // loading: false,
  },
  reducers: {
    setEvents: (state, action) => {
      state.data = action.payload;
      //   state.loading = false;
    },
  },
});

export const { setEvents, setLoading } = eventsSlice.actions;
export default eventsSlice.reducer;
