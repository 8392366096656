import { Component } from "react";

class BlogSection extends Component {
  render() {
    return (
      <div
        className="blog-section padding-top padding-bottom"
        style={{ backgroundImage: "url(/assets/images/blog/bg.jpg)" }}
      >
        <div className="container">
          {/* <div className="section-header" style={{ marginTop: "50px" }}>
            <p>{subtitle}</p>
            <h2>{title}</h2>
          </div> */}
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cta-content">
                <p className="text-uppercase ls-2">Greetings,</p>
                <p className="mb-4">
                  We at The Final 9, simply put, are huge music nuts. We all
                  grew up in the 70's and 80's and 90's and have a big affinity
                  for music. Some of us are professional singers, some of us
                  play instruments both recreationally and professionally, and
                  some of us just live for it as a listening pleasure.
                </p>
                <p>
                  So much has changed over the years and we are just trying to
                  get you artists paid (pretty well, in fact) for your talents.
                  And hopefully help spawn your career(s).
                </p>

                <p>
                  There's no more selling albums (not much), and touring is
                  tough. We are hopeful that you can simply come here and
                  compete in a{" "}
                  <span
                    className="theme-color "
                    style={{ fontWeight: "bolder" }}
                  >
                    Battle of The Bands
                  </span>{" "}
                  with your craft and make some good money and evolve your
                  craft.
                </p>
                <p>
                  Let's face it, the streaming companies have taken over and
                  they're not really in the business of{" "}
                  <span
                    className="theme-color "
                    style={{ fontWeight: "bolder" }}
                  >
                    getting you paid.
                  </span>{" "}
                  They're into answering to Wall Street with their profits and
                  artists will always be challenged to{" "}
                  <span
                    className="theme-color "
                    style={{ fontWeight: "bolder" }}
                  >
                    make a career{" "}
                  </span>{" "}
                  uploading their music to an entity that is answering to Wall
                  Street. They now are employing AI to copy beats and synthesize
                  music electronically making musicians obsolete. Fuck that.
                </p>
                <div className="my-2">
                  <h2 className="theme-color text-center">
                    Here is a very recent video from Rick Beato explaining:
                  </h2>
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/1bZ0OSEViyo?si=kLhV2X4iXgGLAuyZ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  />
                </div>
                <p>
                  We aim to be very different. We pay back 70% of all fees to
                  our artists and have no intention of{" "}
                  <span
                    className="theme-color "
                    style={{ fontWeight: "bolder" }}
                  >
                    talking to Wall Street
                  </span>{" "}
                  and/or becoming some evil streaming company
                </p>
                <p>
                  We just want to advance some great music into the world and
                  keep{" "}
                  <span
                    className="theme-color "
                    style={{ fontWeight: "bolder" }}
                  >
                    human
                  </span>{" "}
                  music alive and beating!
                </p>
                <p>
                  Thank you so much and we hope that you love this site and
                  evangelize it to make it bigger and better
                </p>

                <p
                  className="theme-color text-start pt-4"
                  style={{ fontWeight: "bolder" }}
                >
                  The Final 9 Corporation
                </p>
                {/* <Link to="/signup" className="default-button"><span>{btnText} <i className="icofont-circled-right"></i></span></Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSection;
