/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TheFinal9 from "../assets/images/The_Final_9_Battle.png";
import Header from "../component/layout/header";
import Winner from "../component/section/Winner";
import { axiosInstance } from "../config/AxiosInstance";
import { toast } from "react-toastify";
// import Footer from "../component/layout/footer";
import moment from "moment/moment";
import { setEvents } from "../slice/eventslice";
import { useDispatch } from "react-redux";
import Footer from "../component/layout/footer";

const GameListTwoSection = () => {
  const navigate = useNavigate();
  
  const { slug } = useParams();
  const [songs, setSongs] = useState([]);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const Published_songs = () => {
    setLoader(true);
    axiosInstance
      .get(`/events/${slug}/published_songs`)
      .then((response) => {
        console.log(response, "new Data");
        if (response.data.status === true) {
          setSongs(response?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  const Events = () => {
    setLoader(true);
    axiosInstance
      .get("/events")
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setLoader(false);
          response.data.data.data.filter((event) => {
            const startCheck = moment().isSameOrAfter(
              moment(event.event_start_date, "YYYY-MM-DD")
            );
            const endCheck = moment().isSameOrBefore(
              moment(event.event_end_date, "YYYY-MM-DD")
            );
            dispatch(setEvents(event));
            return startCheck && endCheck;
          });
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  useEffect(() => {
    Events();
    Published_songs();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("success")) {
      toast.success("Payment SuccessFully Done!");
      navigate(`/song/${slug}`);
    } else if (urlParams.has("failed")) {
      toast.error("Payment Failed!");
      navigate(`/song/${slug}`);
    }
  }, []);

  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="bg-winner">
        <div className="pt-6">
          <div className="text-center">
            <img src={TheFinal9} className="Winner-logo" alt="" />
          </div>
        </div>
        {/* <GameListTwo event={event} />  */}
        <Winner id={slug} event={songs} />
      </div>
      <Footer />
      {/* <CtaSection imgUrl={"assets/images/cta/01.png"} /> */}
    </Fragment>
  );
};

export default GameListTwoSection;
