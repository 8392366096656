/* eslint-disable react-hooks/exhaustive-deps */
import {
  faHeart as faHeartregular,
  faPauseCircle,
  faPlayCircle,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import rock from "../../assets/images/Rock.png";
import pop from "../../assets/images/pop.png";
import { axiosInstance } from "../../config/AxiosInstance";

const Winner = (props) => {
  const navigate = useNavigate();
  const isAuthenicated = localStorage.getItem("Unauthenticated");
  const { id } = props;
  const [data, setData] = useState([]);
  const [oldVotes, setOldVotes] = useState(null);
  const { events } = useSelector((state) => state);
  const [isfinal, setIsfinal] = useState(null);
  const [playingsong, setPlayingsong] = useState(0);

  const firstStart = moment().isSameOrAfter(
    moment(events.data.voter_start_date, "YYYY-MM-DD")
  );
  const firstend = moment().isSameOrBefore(
    moment(events.data.voter_end_date, "YYYY-MM-DD")
  );
  const finalStart = moment().isSameOrAfter(
    moment(events.data.final_voter_start_date, "YYYY-MM-DD")
  );
  const finalend = moment().isSameOrBefore(
    moment(events.data.final_voter_end_date, "YYYY-MM-DD")
  );
  const First_9 = () => {
    axiosInstance
      .get(`events/${id}/published_songs`)
      .then((response) => {
        console.log(response, "published_SOngs");
        if (response.data.status === true) {
          setIsfinal(false);
          setData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const final_9 = () => {
    axiosInstance
      .get(`events/${id}/final9`)
      .then((response) => {
        if (response.data.status === true) {
          setIsfinal(true);
          console.log(response, "response");
          setData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Vote = (id) => {
    const formdata = new FormData();
    if (firstStart && firstend) {
      formdata.append("type", "first");
    } else if (finalStart && finalend) {
      formdata.append("type", "final");
    }
    axiosInstance
      .post(`voter/songs/${id}/vote/pay`, formdata)
      .then((response) => {
        if (response.data.status === true) {
          if (response.data.data !== "") {
            window.location.href = response.data.data;
          } else {
            old_Votes();
            if (firstStart && firstend) {
              First_9();
            } else if (finalStart && finalend) {
              final_9();
            }
          }
          // toast.success("Vote sucessfully");
        } else {
          const error = response.data.error;
          console.log(error, "Voting");
          // error.map((e) => toast.error(e));
        }
      })
      .catch((error) => {
        console.log(error, "Voting");
        if (error?.response?.data?.message === "Unauthenticated.") {
          navigate("/login");
        }
      });
  };
  const DeleteVote = (id) => {
    const formdata = new FormData();
    if (firstStart && firstend) {
      formdata.append("type", "first");
    } else if (finalStart && finalend) {
      formdata.append("type", "final");
    }
    axiosInstance
      .delete(`voter/votes/${id}`, formdata)
      .then((response) => {
        console.log(response, "Voting");
        if (response.data === 1) {
          old_Votes();
          if (firstStart && firstend) {
            First_9();
          } else if (finalStart && finalend) {
            final_9();
          }

          // toast.success("Vote Deleted");
        }
        // else {
        //   // const error = response.data.error;
        //   // error.map((e) => toast.error(e));
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const old_Votes = () => {
    axiosInstance
      .get(
        `voter/events/${id}/vote_count?type=${
          finalStart && finalend ? "final" : "first"
        }`
      )
      .then((response) => {
        console.log(response, "votes");
        if (response.data.status) {
          setOldVotes(response.data.data);
        } else {
        }
      })
      .catch((error) => {
        // toast.error(error);
        console.log(error);
      });
  };
  useEffect(() => {
    if (isAuthenicated) {
      old_Votes();
    }
  }, []);
  useEffect(() => {
    if (firstStart && firstend) {
      First_9();
    } else if (finalStart && finalend) {
      final_9();
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (firstStart && firstend) {
        First_9();
      } else if (finalStart && finalend) {
        final_9();
      }
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  console.log(
    "First=>",
    firstStart && firstend,
    "Final=>",
    finalStart,
    finalend
  );
  const audioRef = useRef(null);

  const playPauseHandler = (songId) => {
    if (songId === playingsong) {
      // If the clicked song is already playing, pause it
      audioRef.current.pause();
      setPlayingsong(null);
    } else {
      // If a different song is clicked, play it
      audioRef.current.src = data.find(
        (song) => song.id === songId
      )?.judge_selected_song?.judge_song?.song?.song;
      audioRef.current.play();
      setPlayingsong(songId);
    }
  };
  return (
    <>
      <div className="container">
        <h2
          className={`${
            !isfinal ? "text-white" : "text-muted"
          } text-center c-pointer`}
        >
          <span>
            <u>The First 9</u>
          </span>{" "}
          <span
            className={`${isfinal ? "text-white" : "text-muted"} c-pointer`}
          >
            <u>The Final 9</u>
          </span>
        </h2>
        <div className="d-flex justify-content-end">
          <p className="fs-6 pt-1 text-capitalize">
            {(firstStart && firstend) || (finalStart && finalend)
              ? oldVotes && (
                  <>
                    Remaining Votes{" "}
                    {`${oldVotes?.casted_votes ?? 0} / ${
                      oldVotes?.total_available_votes ?? 0
                    }`}
                  </>
                )
              : null}
          </p>
        </div>
        <div className="border-bottom border-3" />
        <div className="row">
          {firstStart && firstend ? (
            <>
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="mt-3">
                  {data
                    ?.filter(
                      (song) =>
                        song?.judge_selected_song?.judge_song?.song
                          ?.genre_id === 1
                    )
                    ?.sort(
                      (a, b) =>
                        b?.judge_selected_song?.judge_song?.song?.vote_count -
                        a?.judge_selected_song?.judge_song?.song?.vote_count
                    )
                    ?.map((e, index) => (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <h4>
                            <span>
                              <i className="icofont-dollar" />
                            </span>
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :{" "}
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div>
                            <h4>
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {finalStart && finalend
                                ? e?.judge_selected_song?.judge_song?.song
                                    ?.final_vote_count
                                : e?.judge_selected_song?.judge_song?.song
                                    ?.vote_count}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  <audio ref={audioRef}></audio>
                </div>
                <div className="text-center">
                  <img src={rock} alt="" className="w-50" />
                </div>
              </div>
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="mt-3">
                  {data
                    ?.filter(
                      (song) =>
                        song?.judge_selected_song?.judge_song?.song
                          ?.genre_id === 2
                    )
                    ?.sort(
                      (a, b) =>
                        b?.judge_selected_song?.judge_song?.song?.vote_count -
                        a?.judge_selected_song?.judge_song?.song?.vote_count
                    )
                    ?.map((e, index) => (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <h4>
                            <span>
                              <i className="icofont-dollar" />
                            </span>
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :{" "}
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div>
                            <h4>
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {finalStart && finalend
                                ? e?.judge_selected_song?.judge_song?.song
                                    ?.final_vote_count
                                : e?.judge_selected_song?.judge_song?.song
                                    ?.vote_count}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  <audio ref={audioRef}></audio>
                </div>
                <div className="text-center">
                  <img src={pop} alt="" className="w-50" />
                </div>
              </div>
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="mt-3">
                  {data
                    ?.filter(
                      (song) =>
                        song?.judge_selected_song?.judge_song?.song
                          ?.genre_id === 3
                    )
                    ?.sort(
                      (a, b) =>
                        b?.judge_selected_song?.judge_song?.song?.vote_count -
                        a?.judge_selected_song?.judge_song?.song?.vote_count
                    )
                    ?.map((e, index) => (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <h4>
                            <span>
                              <i className="icofont-dollar" />
                            </span>
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :{" "}
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div>
                            <h4>
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {finalStart && finalend
                                ? e?.judge_selected_song?.judge_song?.song
                                    ?.final_vote_count
                                : e?.judge_selected_song?.judge_song?.song
                                    ?.vote_count}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  <audio ref={audioRef}></audio>
                </div>
                <div className="text-center">
                  <img
                    src={"/assets/images/short/hiphop.png"}
                    alt=""
                    style={{ width: "48%" }}
                    className="mt-3"
                  />
                </div>
              </div>
            </>
          ) : finalStart && finalend ? (
            <div className="col-md-12">
              <div className="my-5 WinnerPaddin4">
                {data
                  ?.sort(
                    (a, b) =>
                      b?.judge_selected_song?.judge_song?.song
                        ?.final_vote_count -
                      a?.judge_selected_song?.judge_song?.song?.final_vote_count
                  )
                  ?.map((e, index) =>
                    index === 0 ? (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="row">
                          <h4
                            className="Winnerh4 Winnerh4Size col-lg-3 col-3"
                            id="Winnerh4Heading1"
                          >
                            <span style={{ paddingRight: "15px" }}>
                              {index + 1}
                              <sup className="text-lowercase">st</sup>
                            </span>
                            <span>
                              <i className="icofont-dollar" />
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :
                            </span>
                          </h4>
                          <h4
                            className="Winnerh4 Winnerh4Size1 col-lg-6 col-4 text-center"
                            id="Winnerh4Heading1"
                          >
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div className="Winnerh4Size2 col-lg-3 col-5 text-end">
                            <div id="font-icon">
                              <img
                                src={
                                  e?.judge_selected_song?.judge_song?.song
                                    ?.genre_id === 1
                                    ? "/assets/images/short/rock.png"
                                    : e?.judge_selected_song?.judge_song?.song
                                        ?.genre_id === 2
                                    ? "/assets/images/short/pop.png"
                                    : "/assets/images/short/hiphop.png"
                                }
                                alt=""
                                width={66}
                                height={66}
                                id="WinnerImg"
                              />
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              <span
                                className="font-bold"
                                style={{ fontWeight: "bold" }}
                              >
                                {
                                  e?.judge_selected_song?.judge_song?.song
                                    ?.final_vote_count
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : index === 1 ? (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="row">
                          <h4
                            className="Winnerh4 Winnerh4Size col-lg-3 col-3 Winnerh4ps2"
                            id="Winnerh4Heading2"
                          >
                            <span className="" style={{ paddingRight: "15px" }}>
                              {index + 1}
                              <sup className="text-lowercase">nd</sup>
                            </span>
                            <span>
                              <i className="icofont-dollar" />
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :
                            </span>
                          </h4>
                          <h4
                            className="Winnerh4 Winnerh4Size1 col-lg-6 col-4 text-center"
                            id="Winnerh4Heading2"
                          >
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div className="Winnerh4Size2 col-lg-3 col-5 text-end">
                            <h4 className="font-icon2">
                              <img
                                src={
                                  e?.judge_selected_song?.judge_song?.song
                                    ?.genre_id === 1
                                    ? "/assets/images/short/rock.png"
                                    : e?.judge_selected_song?.judge_song?.song
                                        ?.genre_id === 2
                                    ? "/assets/images/short/pop.png"
                                    : "/assets/images/short/hiphop.png"
                                }
                                alt=""
                                width={66}
                                height={66}
                                id="WinnerImg"
                              />
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {
                                e?.judge_selected_song?.judge_song?.song
                                  ?.final_vote_count
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    ) : index === 2 ? (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="row">
                          <h4
                            className="Winnerh4 Winnerh4Size col-lg-3 col-3 Winnerh4ps5"
                            id="Winnerh4Heading3"
                          >
                            <span style={{ paddingRight: "15px" }}>
                              {index + 1}
                              <sup className="text-lowercase">rd</sup>
                            </span>
                            <span>
                              <i className="icofont-dollar" />
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :
                            </span>
                          </h4>
                          <h4
                            className="Winnerh4 Winnerh4Size1 col-lg-6 col-4 text-center"
                            id="Winnerh4Heading3"
                          >
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div className="Winnerh4Size2 col-lg-3 col-5 text-end">
                            <h4 className="font-icon3">
                              <img
                                src={
                                  e?.judge_selected_song?.judge_song?.song
                                    ?.genre_id === 1
                                    ? "/assets/images/short/rock.png"
                                    : e?.judge_selected_song?.judge_song?.song
                                        ?.genre_id === 2
                                    ? "/assets/images/short/pop.png"
                                    : "/assets/images/short/hiphop.png"
                                }
                                alt=""
                                width={66}
                                height={66}
                                id="WinnerImg"
                              />
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {
                                e?.judge_selected_song?.judge_song?.song
                                  ?.final_vote_count
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={e.id}
                        className="person"
                        style={{
                          alignItems: "center",
                          transform: `translateY(${index * 2}px)`,
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <div className="row">
                          <h4
                            className="Winnerh4 Winnerh4Size col-lg-3 col-3 Winnerh4ps5"
                            id="Winnerh4Heading4"
                          >
                            <span style={{ paddingLeft: "30px" }}>
                              <i className="icofont-dollar" />
                              {e?.judge_selected_song?.judge_song?.song
                                ?.winning_amount?.song_winning_amount ?? 0}
                              :
                            </span>
                          </h4>
                          <h4
                            className="Winnerh4 Winnerh4Size1 col-lg-6 col-4 text-center"
                            id="Winnerh4Heading4"
                          >
                            <span className="ml-2">
                              {e?.judge_selected_song?.judge_song?.song?.title}
                            </span>
                          </h4>
                          <div className="Winnerh4Size2 col-lg-3 col-5 text-end">
                            <h4 className="font-icon5">
                              <img
                                src={
                                  e?.judge_selected_song?.judge_song?.song
                                    ?.genre_id === 1
                                    ? "/assets/images/short/rock.png"
                                    : e?.judge_selected_song?.judge_song?.song
                                        ?.genre_id === 2
                                    ? "/assets/images/short/pop.png"
                                    : "/assets/images/short/hiphop.png"
                                }
                                alt=""
                                width={66}
                                height={66}
                                id="WinnerImg"
                              />
                              <FontAwesomeIcon
                                onClick={() =>
                                  Vote(
                                    e?.judge_selected_song?.judge_song.song?.id
                                  )
                                }
                                icon={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? faHeart
                                    : faHeartregular
                                }
                                color={
                                  e?.judge_selected_song?.judge_song.song
                                    ?.my_votes.length > 0
                                    ? "red"
                                    : "#fff"
                                }
                                size="lg"
                                className="me-1"
                              />
                              {e?.judge_selected_song?.judge_song.song?.my_votes
                                .length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() =>
                                    DeleteVote(
                                      e?.judge_selected_song?.judge_song.song
                                        ?.my_votes?.[0]?.id
                                    )
                                  }
                                  icon={faTrashAlt}
                                  color={"#fff"}
                                  size="lg"
                                />
                              )}
                              <FontAwesomeIcon
                                onClick={() => playPauseHandler(e.id)}
                                icon={
                                  e.id === playingsong
                                    ? faPauseCircle
                                    : faPlayCircle
                                }
                                color={"#fff"}
                                size="lg"
                              />{" "}
                              {
                                e?.judge_selected_song?.judge_song?.song
                                  ?.final_vote_count
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                <audio ref={audioRef}></audio>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="text-center">
                  <img src={rock} alt="" className="w-50" />
                </div>
              </div>
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="text-center">
                  <img src={pop} alt="" className="w-50" />
                </div>
              </div>
              <div className="col-md-4  col-lg-4 col-sm-4 col-12">
                <div className="text-center">
                  <img
                    src={"/assets/images/short/hiphop.png"}
                    alt=""
                    style={{ width: "48%" }}
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Winner;
