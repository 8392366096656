/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import Header from "../component/layout/header";
import CtaSection from "../component/section/cta";
import Footer from "../component/layout/footer";

const PaytoVote = () => {
  return (
    <Fragment>
      <Header />
      <CtaSection imgUrl={"assets/images/whypaytovote.png"} />
      <Footer />
    </Fragment>
  );
};

export default PaytoVote;
