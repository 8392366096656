import React from "react";

const ContestdateCompent = () => {
  return (
    <div
      className="blog-section padding-top padding-bottom"
      style={{ backgroundImage: "url(/assets/images/blog/bg.jpg)" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="cta-content">
              <h2 className="text-uppercase text-center ls-2">
                CURRENT CONTEST DATES Sep-Oct 2024
              </h2>
              <div className="mt-3">
                <p>
                  Sep 1-Sep 30: Artist’s submit songs + judging behind the
                  scenes
                </p>
                <p>Oct 1-Oct 15: The First 9 Public Voting</p>

                <p>Oct 15-Oct 31: The Final 9 Public Voting</p>
                <p className="theme-color fw-bold">
                  *NOTE: September 1st, 2024 marks the inaugural “The Final 9:
                  Battle of The Bands” contest. It will end October 31st. It
                  will be limited to only 50 entrants per genre, 150 total. 27
                  songs will still advance to The First 9 competition and 9
                  songs will advance to The FInal 9 competition (per contest
                  format). We will do a full launch on November 1st, 2024. That
                  contest will commence the 300 songs (900 total) per genre
                  contest format.
                </p>
              </div>
            </div>
          </div>
          {/* changes */}
        </div>
      </div>
    </div>
  );
};

export default ContestdateCompent;
