import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
// import '../../assets/css/header.css'
// const contactNumber = "+800-123-4567 6587";
// const contactAddress = "Beverley, New York 224 USA";

// const SocialMediaList = [
//   {
//     IconName: "icofont-facebook-messenger",
//     IconLink: "#",
//   },
//   {
//     IconName: "icofont-twitter",
//     IconLink: "#",
//   },
//   {
//     IconName: "icofont-vimeo",
//     IconLink: "#",
//   },
//   {
//     IconName: "icofont-skype",
//     IconLink: "#",
//   },
//   {
//     IconName: "icofont-rss-feed",
//     IconLink: "#",
//   },
// ];

const Header = () => {
  const isLogin = sessionStorage.getItem("Token");
  const menuTrigger = () => {
    document.querySelector(".menu").classList.toggle("active");
    document.querySelector(".header-bar").classList.toggle("active");
  };

  // const menuTriggerTwo = () => {
  //   document.querySelector(".header-top").classList.toggle("open");
  // };

  return (
    <header className={`header-section `}>
      <div className="container">
        <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
          <div className="brand-logo d-none d-lg-inline-block">
            <div className="logo">
              <Link to="/">
                <img
                  style={{ height: 80 }}
                  src="/assets/images/logo/logo.png"
                  alt="logo"
                  className="img-fluid logo_old"
                />
              </Link>
            </div>
          </div>
          <div className="header-menu-part">
            <div className="header-bottom">
              <div className="header-wrapper justify-content-lg-start">
                <div className="mobile-logo d-lg-none">
                  <Link to="/">
                    <img src="/assets/images/logo/logo.png" alt="logo" />
                  </Link>
                </div>
                <div className="menu-area">
                  <ul className="menu">
                    {/* <li className="menu-item-has-children">
                      <Link to={""} role="button">
                        Features
                      </Link>
                      <ul
                        className="submenu dropdown-menu"
                        aria-labelledby="dropdown"
                      >
                        <li>
                          <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                          <NavLink to="/gallery">gallery</NavLink>
                        </li>
                        <li>
                          <NavLink to="/game-list">game list 1</NavLink>
                        </li>
                        <li>
                          <NavLink to="/game-list2">game list 2</NavLink>
                        </li>
                        <li>
                          <NavLink to="/partners">partners</NavLink>
                        </li>
                        <li>
                          <NavLink to="/achievements">achievement</NavLink>
                        </li>
                        <li>
                          <NavLink to="/team">team</NavLink>
                        </li>
                        <li>
                          <NavLink to="/team-single">team single</NavLink>
                        </li>
                        <li>
                          <NavLink to="/404">404 Page</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to={""} role="button">
                        Shop
                      </Link>
                      <ul className="submenu dropdown-menu">
                        <li>
                          <NavLink to="/shop">shop</NavLink>
                        </li>
                        <li>
                          <NavLink to="/shop-single">Shop Details</NavLink>
                        </li>
                        <li>
                          <NavLink to="/cart-page">Cart Page</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to={""} role="button">
                        Blog
                      </Link>
                      <ul className="submenu dropdown-menu">
                        <li>
                          <NavLink to="/blog">Blog</NavLink>
                        </li>
                        <li>
                          <NavLink to="/blog-2">Blog 2</NavLink>
                        </li>
                        <li>
                          <NavLink to="/blog-single">Blog Single</NavLink>
                        </li>
                      </ul>
                    </li> */}

                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="dropdown ">
                      <NavLink to="/thefinalwork">
                        HOW THE FINAL 9 WORKS{" "}
                        <i class="fa-solid fa-caret-down"></i>
                      </NavLink>
                      <ul className="dropdown-content">
                        <li>
                          <NavLink to="/paytovote" className="mb-2 text-dark">
                            HOW YOUR VOTE CAN HELP
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/paytouserguide" className="mb-2 text-dark">
                          USER GUIDE FOR THE FINAL 9
                          </NavLink>
                        </li>
                        {/* <li>
                          
                          <NavLink to="/Music" className="mb-2 text-dark">
                            A MESSAGE FROM THE PRESIDENT
                            </NavLink>
                        </li> */}
                        <li>
                          <NavLink
                            to="/Music"
                            className="mb-2 text-dark text-uppercase"
                          >
                            A Message From the Company
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/contest_dates"
                            className="mb-2 text-dark text-uppercase"
                          >
                            CURRENT CONTEST DATES{" "}
                          </NavLink>
                        </li>
                        {/* Add more dropdown items as needed */}
                      </ul>
                        <br />
                    </li>
                    <li className="Second">
                      <li>
                        <NavLink to="/thefinalwork">
                          HOW THE FINAL 9 WORKS
                        </NavLink>
                      </li>
                      <li>
                          <NavLink to="/paytouserguide">
                          USER GUIDE FOR THE FINAL 9
                          </NavLink>
                        </li>
                      <li>
                        <NavLink to="/paytovote">WHY PAY TO VOTE</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Music">
                          A Message From the Company
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/contest_dates">
                          CURRENT CONTEST DATES{" "}
                        </NavLink>
                      </li>
                    </li>

                    {/* <li>
                      <NavLink to="/contact">Contact</NavLink>
                    </li> */}
                    <li>
                      <a href="https://artist.thefinal9.com/">
                        <i className="icofont-mic"></i>{" "}
                        <span>UPLOAD A SONG</span>
                      </a>
                    </li>
                  </ul>
                  {/* <Link to="/signup" className="signup">
                    <i className="icofont-mic "></i> <span>UPLOAD SONG</span>
                  </Link> */}
                  {isLogin ? (
                    <>
                      <Link to="/Profile" className="login">
                        <i className="icofont-user"></i> <span>Profile</span>{" "}
                      </Link>
                      <Link
                        to="/login"
                        className="signup"
                        onClick={() => {
                          sessionStorage.removeItem("SignUp");
                          sessionStorage.removeItem("Token");
                          sessionStorage.removeItem("userDetail");
                          toast.success("Logout successfully");
                        }}
                      >
                        <i className="icofont-logout"></i> <span>Logout</span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/login" className="login">
                        <i className="icofont-user"></i> <span>USER login</span>{" "}
                      </Link>
                      <Link
                        to="https://artist.thefinal9.com/"
                        className="signup"
                      >
                        <i className="icofont-users"></i>{" "}
                        <span>ARTIST login</span>
                      </Link>
                    </>
                  )}
                  <div className="header-bar d-lg-none" onClick={menuTrigger}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <DropdownButton
                    id="dropdown-item-button"
                    className="d-lg-none d-block "
                    style={{
                      marginLeft: "10px",
                      background: "transparent",
                    }}
                    title={
                      <span>
                        {" "}
                        <i class="fa-solid fa-right-to-bracket"></i>
                      </span>
                    }
                  >
                    <Link to="/login">
                      <Dropdown.Item as="button">
                        {" "}
                        <i className="icofont-user"></i>{" "}
                        <span className="">USER login </span>
                      </Dropdown.Item>
                    </Link>
                    <Link to="https://artist.thefinal9.com/">
                      <Dropdown.Item as="button">
                        {" "}
                        <i className="icofont-users"></i> ARTIST login
                      </Dropdown.Item>
                    </Link>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
