import moment from "moment/moment";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setEvents } from "../../slice/eventslice";

const BannerSection = ({ event }) => {
  const btnText = "View Event";
  const ref = useRef();
  const dispatch = useDispatch();
  const history = useNavigate();

  return (
    <>
      {event.filter((event) => {
        const startCheck = moment().isSameOrAfter(
          moment(event.event_start_date, "YYYY-MM-DD")
        );
        const endCheck = moment().isSameOrBefore(
          moment(event.event_end_date, "YYYY-MM-DD")
        );
        dispatch(setEvents(event));
        console.log(event,"<===firstEvent")
        history(`/song/${event?.id}`);
        return startCheck && endCheck;
      }).length > 0 ? (
        event.map(
          (e, i) =>
            i === 0 && (
              <div className="position-relative">
                <div className="img-overlay"></div>
                <section
                  className="banner-section"
                  style={{
                    backgroundImage: `url(${e.images[0].image})`,
                  }}
                >
                  <div className="container">
                    <div className="">
                      <div className="banner-content position-relative z-1 text-center">
                        <h4 className="fw-normal theme-color mb-4">
                          {"Event Started"}
                        </h4>
                        <h1>{e.title}</h1>
                        <p>{`Date: ${moment(e.event_start_date).format(
                          "DD-MMM-YY"
                        )} - ${moment(e.event_end_date).format(
                          "DD-MMM-YY"
                        )}`}</p>
                        <Link
                          ref={ref}
                          to={`/song/${e.id}`}
                          className="default-button reverse-effect"
                        >
                          <span>
                            {btnText} <i className="icofont-play-alt-1"></i>
                          </span>{" "}
                        </Link>
                      </div>
                      <div className="banner-thumb d-flex flex-wrap justify-content-center justify-content-between align-items-center align-items-lg-end"></div>
                    </div>
                  </div>
                </section>
              </div>
            )
        )
      ) : event.filter((event) =>
          moment().isBefore(moment(event.event_start_date, "YYYY-MM-DD"))
        ).length > 0 ? (
        event.map(
          (e, i) =>
            i === 0 && (
              <div className="position-relative">
                <div className="img-overlay"></div>
                <section
                  className="banner-section"
                  style={{
                    backgroundImage: `url(${e.images[0].image})`,
                  }}
                >
                  <div className="container">
                    <div className="">
                      <div className="banner-content position-relative z-1 text-center">
                        <h4 className="fw-normal theme-color mb-4">
                          {"Upcoming Events"}
                        </h4>
                        <h1>{e.title}</h1>
                        <p>{`Date: ${moment(e.event_start_date).format(
                          "DD-MMM-YY"
                        )} - ${moment(e.event_end_date).format(
                          "DD-MMM-YY"
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )
        )
      ) : (
        <div className="position-relative">
          <div className="img-overlay"></div>
          <section
            className="banner-section"
            style={
              {
                // backgroundImage: `url(${e.images[0].image})`,
              }
            }
          >
            <div className="container">
              <div className="">
                <div className="banner-content position-relative z-1 text-center">
                  <h4 className="fw-normal theme-color mb-4">
                    {"Events Ended"}
                  </h4>
                  <h1>{"No Event Found"}</h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default BannerSection;
