/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react";
import Header from "../component/layout/header";
import BlogSection from "../component/section/blog";
import Footer from "../component/layout/footer";

const Music = () => {
  return (
    <Fragment>
      <Header />
      <BlogSection />
      <Footer />
    </Fragment>
  );
};

export default Music;
