import React from "react";
import Header from "../component/layout/header";
import Footer from "../component/layout/footer";
import ContestdateCompent from "../component/section/Contestdate";

const Contestdates = () => {
  return (
    <>
      <Header />
      <ContestdateCompent />
      <Footer />
    </>
  );
};

export default Contestdates;
