/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { axiosInstance } from "../config/AxiosInstance";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Footer from "../component/layout/footer";

const title = "Register Now";

const SignUp = () => {
  const navigate = useNavigate();
  const [regFName, setRegFName] = useState("");
  const [regLName, setRegLName] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const SignUp = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    formdata.append("fname", regFName);
    formdata.append("lname", regLName);
    formdata.append("email", regEmail);
    formdata.append("password", regPassword);
    formdata.append("image", image[0]);
    axiosInstance
      .post("voters", formdata)
      .then((response) => {
        if (response.data.status === true) {
          toast.success("Sign Up Successfully");
          setLoader(false);
          navigate("/login");
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <>
      <Header />
      <PageHeader title={"REGISTRATION PAGE"} curPage={"Sign Up"} />
      <div className="login-section padding-top padding-bottom">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="item01"
                  value={regFName}
                  onChange={(e) => setRegFName(e.target.value)}
                  placeholder="First Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="item02"
                  value={regLName}
                  onChange={(e) => setRegLName(e.target.value)}
                  placeholder="Last Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  id="item03"
                  value={regEmail}
                  onChange={(e) => setRegEmail(e.target.value)}
                  placeholder="Your email *"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  id="item04"
                  value={regPassword}
                  onChange={(e) => setRegPassword(e.target.value)}
                  placeholder="Password *"
                />
              </div>
              <div className="form-group">
                <h6 className="text-start">Profile Image</h6>
                <input
                  type="file"
                  id="item05"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
              <div className="account-bottom" style={{ paddingTop: "20px" }}>
              <span className="d-block cate pt-10">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  style={{ width: "auto" }}
                  onChange={(event) => setChecked(event.target.checked)}
                />{" "}
                <label htmlFor="" style={{ fontSize: "12px" }}>
                  AGREE to{" "}
                  <a target="_blank" href="/assets/pdf/UA.pdf">
                    USER AGREEMENT
                  </a>{" "}
                  and{" "}
                  <a target="_blank" href="/assets/pdf/T&C.pdf">
                    TERMS AND CONDITIONS
                  </a>
                </label>
              </span>
            </div>
              <div className="form-group">
                <button
                  className="d-block default-button"
                  disabled={loader || !checked}
                  onClick={SignUp}
                >
                  {loader ? (
                    <span>
                      Loading{" "}
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin-pulse"
                      />{" "}
                    </span>
                  ) : (
                    <span>Get Started Now</span>
                  )}
                </button>
              </div>
            </form>

            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Are you a member? <Link to="/login">Login</Link>
              </span>
              {/* <span className="or">
                <span>or</span>
              </span>
              <h5 className="subtitle">Register With Social Media</h5>
              <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center mt-4">
                <SocialMedia />
              </ul> */}
            </div>

            <div className="account-bottom" style={{ paddingTop: "20px" }}>
              <span className="d-block cate pt-10">
                You can review <Link to="/privacyPolicy"> Privacy policy</Link>
              </span>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
