/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faImagePortrait } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { axiosInstance } from "../config/AxiosInstance";
import Footer from "../component/layout/footer";

const Profile = () => {
  const ref = useRef();
  const imgInput = () => ref.current.click();
  const [votes, setVotes] = useState([]);
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  let isuser = sessionStorage.getItem("userDetail");
  isuser = JSON.parse(isuser);
  const getVoter = () => {
    setLoader(true);
    axiosInstance
      .get(`voter/voters/${isuser.id}`)
      .then((response) => {
        console.log("response====>", response);
        if (response.data.status === true) {
          setUser(response.data.data);
          sessionStorage.setItem(
            "userDetail",
            JSON.stringify(response.data.data)
          );
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
        console.log(error);
      });
  };
  const handleImageUpdate = (files) => {
    console.log(files[0]);
    setLoader(true);
    const formdata = new FormData();
    formdata.append("image", files[0]);

    axiosInstance
      .post(`voter/voters/update_image/${user.id}`, formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          getVoter();
          toast.success(response.data.response);
          setLoader(false);
        } else {
          toast.error(response.data.error);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
        console.log(error);
      });
  };
  const Votes = () => {
    setLoader(true);
    axiosInstance
      .get("voter/votes")
      .then((response) => {
        console.log(response, "votes");
        if (response.data.status) {
          setVotes(response.data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
        console.log(error);
      });
  };
  const Update = () => {
    setLoader(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", user.email);
    urlencoded.append("password", password);
    urlencoded.append("fname", fname ? fname : user.fname);
    urlencoded.append("lname", lname ? lname : user.lname);

    axiosInstance

      .put(`voter/voters/${user.id}`, urlencoded)
      .then((response) => {
        console.log(response, "user respone");
        if (response.data.status === true) {
          getVoter();
          toast.success(response.data.response);
          setLoader(false);
        } else {
          toast.success(response.data.error);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
        console.log(error);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    Votes(); // Scroll to the top of the page on component mount
    getVoter();
  }, []);
  console.log(votes);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <PageHeader title={"Profile"} curPage={"Profile"} />
      <div className="shop-cart padding-top padding-bottom">
        <div className="container">
          <div className="section-wrapper">
            {/* top */}
            <div className="cart-bottom">
              <div className="cart-checkout-box">
                <h3>Profile Details</h3>
              </div>
              <div className="shiping-box">
                <div className="row">
                  <div className="calculate-shiping">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="dropdown">
                          <input
                            type="file"
                            ref={ref}
                            className="d-none"
                            onChange={(e) => handleImageUpdate(e.target.files)}
                          />
                          <img
                            src={`${user?.image}`}
                            alt={"profile img"}
                            style={{
                              borderRadius: "50%",
                              height: 250,
                            }}
                            className="border border-1 p-2 dropdown-toggle w-100"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li onClick={imgInput}>
                              <h6 className="dropdown-item">
                                <FontAwesomeIcon
                                  icon={faImagePortrait}
                                  className="text-success"
                                />{" "}
                                Change Profile Picture
                              </h6>{" "}
                            </li>
                            <li>
                              <h6>
                                <a
                                  className="dropdown-item"
                                  href={`https://api.thefinal9.dev-iuh.xyz/${user?.image}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="text-primary"
                                  />{" "}
                                  View Profile Picture{" "}
                                </a>
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <h6 htmlFor="">First Name</h6>
                            <input
                              type="text"
                              defaultValue={user.fname}
                              onChange={(e) => setFname(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <h6 htmlFor="">Last Name</h6>
                            <input
                              type="text"
                              defaultValue={user.lname}
                              onChange={(e) => setLname(e.target.value)}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6 htmlFor="">Email</h6>
                            <input
                              type="email"
                              defaultValue={user.email}
                              readOnly
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6 htmlFor="">New Password</h6>
                            <input
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="default-button reverse-effect  w-25"
                            onClick={Update}
                          >
                            <span>Update</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* bottom */}
            <div className="cart-top">
              <h3 className=" p-3">Purchase Details</h3>
              <table>
                <thead>
                  <tr>
                    <th className="cat-product">Song Name</th>
                    {/* <th className="cat-product">Song Name</th> */}
                    <th className="cat-price">Genre</th>
                    <th className="cat-quantity">Type</th>
                    <th className="cat-edit">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {votes.length > 0 ? (
                    votes.map((val, i) => (
                      <tr key={i}>
                        <td className="product-item cat-product">
                          {val.song.thumbnail && (
                            <div className="p-thumb">
                              <a
                                href={val.song.thumbnail}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={`${val.song.thumbnail}`}
                                  alt={`${val.song.title}`}
                                />
                              </a>
                            </div>
                          )}
                          <div className="p-content">
                            <Link to="#">{val.song.title}</Link>
                          </div>
                        </td>
                        <td className="cat-price">{val.song.genre.name}</td>
                        <td className="cat-quantity text-center text-capitalize">{val.type}</td>

                        <td className="cat-edit">
                          <div className="badge bg-success">Paid</div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="product-item cat-product">
                        <div className="p-content">No data found </div>
                      </td>
                      <td className="cat-price">{"No data found"}</td>

                      <td className="cat-edit">
                        <div className="badge bg-light text-dark">
                          No data found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Profile;
