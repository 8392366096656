import { Component } from "react";

const title = (
  <h1
    className="mb-3 ctaTitle"
    style={{ marginTop: "15%", textAlign: "center" }}
  >
    User Guide for The Final 9:
    <span className="theme-color text-uppercase">Battle of The Bands</span>!
  </h1>
);

class Userguide extends Component {
  render() {
    return (
      <section className="cta-section padding-bottom">
        <div className="container">
          <div className="cta-wrapper item-layer">
            <div
              className="cta-item px-4 px-sm-5  pt-sm-5 pt-lg-0 " id="userGUIDE"
              style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="cta-content">
                    <p className="theme-color text-uppercase ls-2"></p>
                    {title}
                    <p className="mb-4">
                      We thank you for coming to this site and participating in
                      promoting original music. The process is quite simple
                    </p>
                    <p>
                      There are two rounds to each Final 9 Competition and there
                      are SIX (6) competitions each year.
                    </p>
                    <p>
                      The first round of each competition is named{" "}
                      <b>The First 9</b> where NINE original songs in THREE
                      genres will compete within their respective genres (so 27
                      songs in all) for advancement to the final competition:
                      <b>The Final 9.</b> The top THREE in each genre advance to
                      this final round and will compete head-to-head to see
                      which new song is the “<b>BEST NEW SONG</b>” for the
                      current season.
                    </p>
                    <p>
                      Rankings are derived from most “votes” (the hearts) from
                      the public (you).
                    </p>
                    <p>
                      At the beginning of each round of competition, artists
                      will submit their original music while judging happens
                      simultaneously and this will all take place in the first
                      month of each competition. Once submissions and judging
                      end, public voting for <b>The First 9</b> competition will
                      begin. So public voting begins for <b>The First 9</b> on
                      the first day of February, April, June, August, October,
                      December
                    </p>
                    <p>
                      When the top 3 are finally achieved at the end of two
                      weeks of voting (remember judging happens the first
                      month), <b>The Final 9</b> will now begin and you will
                      have the chance to register and vote again for these NINE
                      finalists from the three genres.
                    </p>
                    <p>
                      Registration fees are $5.40 and give you NINE (9) votes to
                      cast any way you wish. You can register to vote as many
                      times as you wish. <b>The Final 9</b> rounds start anew
                      each time; there is no carryover from The First 9.{" "}
                      <b>The Final 9</b> rounds begin Feb 16, April 16th, June
                      16th, August 16th, October 16th, and November 16th.{" "}
                      <b>The Final 9 lasts TWO WEEKS.</b>
                    </p>
                    <p>
                      To vote, simply click on the “hearts” next to the songs
                      you love whereby you will be prompted to the payment
                      portal. Once your nine votes are used, and you want to
                      vote more, just click on a heart again and you will be
                      sent to the payment portal once more. You will then get
                      nine more votes. If you want to delete a vote, or any
                      votes, just click on the trash can and the vote will be
                      erased.
                    </p>
                    <p>Remember, most “HEARTS” win!</p>
                    <p>That’s it!</p>
                    <p>
                      Thanks again for all your interest and participation and
                      we at The Final 9 company as well as all the artists are
                      exceptionally grateful for your participation.
                    </p>
                    <p>Let’s Rock!</p>
                    <p style={{ fontWeight: "bolder" }}>The Final 9 Team.</p>
                    {/* <Link to="/signup" className="default-button"><span>{btnText} <i className="icofont-circled-right"></i></span></Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Userguide;
