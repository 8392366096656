import { Component } from "react";

const title = (
  <h1
    className="mb-3 ctaTitle"
    style={{ marginTop: "40%", textAlign: "center" }}
  >
    How YOU{" "}
    <span className="theme-color text-uppercase">
      can participate and HELP:
    </span>
  </h1>
);

class CtaSection extends Component {
  render() {
    const { imgUrl } = this.props;
    return (
      <section className="cta-section padding-bottom">
        <div className="container">
          <div className="cta-wrapper item-layer">
            <div
              className="cta-item px-4 px-sm-5 pt-4 pt-sm-5 pt-lg-0 "
              style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="cta-content">
                    <p className="theme-color text-uppercase ls-2"></p>
                    {title}
                    <p className="mb-4">
                      When a music artist uploads onto any one of the music
                      streaming services (which have pretty much taken over
                      music listening outlets) it takes 300 streams to earn $1.
                      That means it will take 1000 streams to buy a cup of
                      coffee for a talented musician. 1 Million streams = $3000.
                    </p>
                    <p>
                      Getting discovered is the biggest challenge and that's
                      where The Final 9 really hopes to make a big difference.
                      There are reportedly over 60,000 new songs uploaded EVERY
                      DAY on streaming sites; how does one even compete for
                      ears?
                    </p>
                    <h4>“The Final 9: Battle of The Bands”!!</h4>
                    <p>
                      You can think of this platform as a website where you are
                      helping new artists continue their craft, award them for
                      their talent, and hopefully, launch a stellar career in
                      music. Your registration fee of $5.40 will help the
                      artists as these fees get paid directly to the top 9
                      winners in the category where you vote for them. You will
                      receive “9 Votes” for each $5.40 to cast however you wish
                      and can register as often as you wish.{" "}
                    </p>
                    <p>
                      Cash awards for top winners competing on The Final 9 can
                      reach $15,000 for each competition, which is significant,
                      and this will hopefully draw more artists to the
                      competition as well as all of you voters, putting The
                      Final 9 on the map as a place to get discovered and
                      financed.
                    </p>
                    <p>
                      Remember, no one person can decide a true "winner". It's
                      the CROWD that tends to always be right. So let's
                      "crowd-up" and advance some new talented artists
                    </p>
                    <p>
                      Thanks for playing! We love you for doing so and we are
                      sure the artists are exceptionally grateful.
                    </p>
                    <p style={{ fontWeight: "bolder" }}>The Final 9 Corp</p>
                    {/* <Link to="/signup" className="default-button"><span>{btnText} <i className="icofont-circled-right"></i></span></Link> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cta-thumb text-end">
                    <img src={imgUrl} alt="gamer-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CtaSection;
